<template>
  <div id="app">
    <div class="update-wrapper" v-if="updateExists && !skip">
      <div class="update-content">
        <h1>Update available</h1>
        <b-button variant="danger" @click="refreshApp">Update now</b-button>
        <span class="update-skip" @click="skip=true">Remind me later</span>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import update from './mixins/update'
export default {
  name: 'App',
  data() {
    return {
      skip: false,
    };
  },
  mixins: [update],
  mounted() {
    this.validateUser();
    let language = window.navigator.userLanguage || window.navigator.language
    language = language.split('-')[0]
    if (language !== 'de' && language !== 'en') language = 'en'
    this.$i18n.locale = language
    document.addEventListener('swOffline', () => this.toast('Warnung', 'Die App hat momentan keine Internetverbindung.', 'warning'), { once: true })
  },
  methods: {
    async validateUser() {
      const token = await localStorage.getItem('token')
      this.axios
        .get('auth/validate', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          this.$store.state.auth.loggedIn = true
          this.$store.state.auth.id = r.data.id
          this.$store.state.auth.preName = r.data.preName
          this.$store.state.auth.lastName = r.data.lastName
          this.$store.state.auth.email = r.data.email
          this.$store.state.auth.admin = r.data.admin
        })
        .catch(() => {
          this.$store.state.auth.loggedIn = false
        })
    },
  },
}
</script>

<style lang="scss">
@use '@/styles/colors';
@use '@/styles/config';
@use '@/styles/dropdown';
@use '@/styles/fonts';
@use '@/styles/general';
@use '@/styles/types';

.update-wrapper {
  position: fixed;
  z-index: 5;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.update-content {
  background-color: white;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.update-skip {
  cursor: pointer;
  margin-top: 1rem;
}
</style>
