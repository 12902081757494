<template>
  <div class="page">
    <div class="header-container">
      <div class="back-item">
        <label class="content link" @click="logout">{{ $t('Home.Logout') }}</label>
      </div>
    </div>
    <div class="header-space"></div>
    <div>
      <label class="title">{{ $t('Home.Title') }}</label>
    </div>
    <div class="header-space"></div>
    <div class="user-list">
      <div class="user-card" v-for="user of users" :key="user._id" @click="showProfile(user)">
        <div class="user-image">
          <img :src="user.imageUrl || fallbackImageUrl(user.preName, user.lastName)" draggable="false" />
        </div>
        <div>
          <label class="heading">{{ user.preName }} {{ user.lastName }}</label>
        </div>
      </div>
    </div>
    <div class="header-space"></div>
    <div class="page-nav">
      <div @click="lastPage()">
        <Button :text="'←'" :disabled="currentPage === 1"></Button>
      </div>
      <div @click="nextPage()">
        <Button :text="'→'" :disabled="currentPage >= pageCount"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../components/Button.vue'

export default {
  name: "Home",
  components: { Button },
  data() {
    return {
      users: [],
      mode: "grid",
      modes: [
        { text: "G", value: "grid" },
        { text: "L (WIP)", value: "list", disabled: true },
      ],
      perPage: 5,
      total: 0,
      currentPage: 1,
      pageCount: 0
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const token = await localStorage.getItem('token')
      this.axios
        .get(`user/list?skip=${(this.currentPage - 1) * this.perPage}&limit=${this.perPage}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.users = res.data.users
          this.total = res.data.total
          this.pageCount = Math.floor(this.total / this.perPage)
        });
    },
    showProfile(p) {
      this.$router.push({
        path: '/profile',
        query: {
          id: p._id,
        },
      })
    },
    nextPage() {
      if (this.currentPage >= this.pageCount) return
      this.currentPage++
      this.getData()
    },
    lastPage() {
      if (this.currentPage <= 1) return
      this.currentPage--
      this.getData()
    }
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/colors';
$user-card-width: 14em;

.user-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1em;
}

.user-image {
  width: calc(#{$user-card-width} - 2em);
  height: calc(#{$user-card-width} - 2em);
  overflow: hidden;
  object-fit: cover;
  object-position: center;

  & img {
    width: calc(#{$user-card-width} - 2em);
    height: calc(#{$user-card-width} - 2em);
    object-fit: cover;
    user-select: none;
  }
}

.user-card {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: $user-card-width;
  padding: 1em;
  background-color: colors.$cards;
  user-select: none;
  cursor: pointer;

  & * {
    cursor: pointer;
  }
}

.page-nav {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media screen and (max-width: 32em) {
  .user-card {
    width: 100%;
    flex-direction: row;

    .user-image {
      width: 4em;
      height: 4em;

      & img {
        width: 4em;
        height: 4em;
      }
    }
  }
}
</style>
