import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage }  from "firebase/messaging";
import './plugins/bootstrap-vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router'
import store from './store/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'

const apiUrls = {
  local: 'http://localhost:3000/',
  dev: 'https://insiders-backend-dev.herokuapp.com/api/v1/',
  prod: 'https://insiders-api.marcrufeis.de/api/v1/',
};

Vue.use(VueAxios, axios);
axios.defaults.baseURL = process.env.VUE_APP_CUSTOM_API_URL || apiUrls[process.env.VUE_APP_API_URL || 'dev'];

const firebaseConfig = {
  apiKey: "AIzaSyA-usvvZHFNMlLR9AoMdDZW_fBe4A90fZo",
  authDomain: "insiders-mr.firebaseapp.com",
  projectId: "insiders-mr",
  storageBucket: "insiders-mr.appspot.com",
  messagingSenderId: "15733513403",
  appId: "1:15733513403:web:02e64db196992bc82d1d9e",
  measurementId: "G-05D0M0DMR8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
Vue.prototype.$firebase = app;
const messaging = getMessaging(app);
onMessage(messaging, (message) => {
  console.log(message);
  this.$bvToast.toast(message.notification.body, {
    title: message.notification.title,
    variant: 'info',
    solid: true,
    autoHideDelay: 2000,
    toaster: 'b-toaster-top-right',
    appendToast: false
  })
});

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    toast: function (title, text, variant = null) {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      })
    },
    logout() {
      localStorage.removeItem('token');
      store.state.loggedIn = false;
      store.state.preName = null;
      store.state.lastName = null;
      router.push('/login');
    },
    fallbackImageUrl(preName, lastName) {
      return `https://avatars.dicebear.com/api/adventurer-neutral/${preName}${lastName}.svg`;
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null) {
      next({
        path: '/login',
      });
      this.toast('Es ist ein Fehler aufgetreten', 'Du musst dich zuerst anmelden!', 'danger')
      store.state.loggedIn = false;
    } else {
      next();
      store.state.loggedIn = true;
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
