<template>
  <div v-bind:class="{ 'disabled': this.disabled, 'button-container': !this.disabled }">
    <label class="content button-text">{{ text }}</label>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/config';
@use '@/styles/colors';

.button-container {
  padding-top: .8em;
  padding-bottom: .8em;
  padding-left: 1.3em;
  padding-right: 1.3em;
  background-color: colors.$accent-1;
  cursor: pointer;
  width: fit-content;
  border-radius: config.$radius;
  transition-duration: config.$transition;
  display: flex;
  align-items: center;

  &:hover {
    background-color: colors.$accent-2;

    & .button-text {
      color: colors.$white;
      user-select: none;
      cursor: pointer;
      font-size: config.$text-size;
    }
  }

  & .button-text {
    color: colors.$white;
    user-select: none;
    cursor: pointer;
    font-size: config.$text-size;
    margin: 0;
  }
}

.disabled {
  padding-top: .8em;
  padding-bottom: .8em;
  padding-left: 1.3em;
  padding-right: 1.3em;
  background-color: colors.$info;
  cursor: default;
  width: fit-content;
  border-radius: config.$radius;
  display: flex;
  align-items: center;

  & .button-text {
    cursor: default;
    user-select: none;
  }
}
</style>
