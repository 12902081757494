import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      loggedIn: false,
      id: '',
      preName: '',
      lastName: '',
      email: '',
      admin: false,
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
