export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      installPrompt: null
    }
  },

  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
      this.showInstallPrompt();
      console.log(`'beforeinstallprompt' event was fired.`);
    });
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },

    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },

    showInstallPrompt() {
      this.$bvModal.msgBoxConfirm(this.$t('General.Install'))
          .then(value => {
            if (value) this.installApp();
          })
          .catch(() => {})
    },

    async installApp() {
      this.installPrompt.prompt();
      const { outcome } = await this.installPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      this.installPrompt = null;
    }
  },
}